<template>
  <layout-full :class="[isSmallScreen ? 'vertical-responsive-sm' : '']">
    <router-view />
  </layout-full>
</template>

<script>
import LayoutFull from '@core/layouts/layout-full/LayoutFull.vue'

export default {
  components: {
    LayoutFull,
  },
  data() {
    return {
      isSmallScreen: false,
    };
  },
  mounted() {
    this.checkScreenWidth();
    window.addEventListener('resize', this.checkScreenWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenWidth);
  },
  methods: {
    checkScreenWidth() {
      this.isSmallScreen = window.innerWidth <= 767;
    },
  },
}
</script>
